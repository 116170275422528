@import 'assets/styles/mixins.scss';

.container {
  :global(.ant-layout-footer) {
    background: #fff;
    height: auto;
    padding: 20px 0;

    :global(.utils__content) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    :global(.links) {
      a {
        margin-left: 30px;
      }
    }

    a {
      font-family: 'EYInterstate-Bold', sans-serif;
      font-size: 13px;
      color: #c4c4cd;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
    }

    @media (max-width: 768px) {
      :global(.links) {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        a {
          margin: 0 !important;
        }
      }
      :global(.utils__content) {
        display: block;
        text-align: center;
      }
    }
  }
}
