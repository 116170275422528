@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  CORE MODULE STYLES */

@font-face {
  font-family: 'EYInterstate-Bold';
  src: url('EYInterstate-Bold.eot'); /* IE9 Compat Modes */
  src: url('EYInterstate-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('EYInterstate-Bold.woff') format('woff'),
    /* Modern Browsers */ url('EYInterstate-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('EYInterstate-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'EYInterstate-Light';
  src: url('EYInterstate-Light.eot'); /* IE9 Compat Modes */
  src: url('EYInterstate-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('EYInterstate-Light.woff') format('woff'),
    /* Modern Browsers */ url('EYInterstate-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('EYInterstate-Light.svg#svgFontName') format('svg'); /* Legacy iOS */
}

html {
  font-size: 13px;
  overflow-y: scroll;
  @media (max-width: $sm-max-width) {
    font-size: 12px;
  }
  color: #747480;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #747480;
}

h1 {
  font-family: 'EYInterstate-Light', sans-serif;
  font-size: 32px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 20px 0;
}

body {
  font-family: 'EYInterstate-Bold', sans-serif;
  color: $text-darken-more;
  background: $gray-lighten-more;
  line-height: 1.7;
  @media (max-width: $sm-max-width) {
    font-size: 12px;
  }
}

svg {
  vertical-align: baseline;
  overflow: auto;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base__font-family;
  color: $text-darken-more;
}

button,
input {
  box-shadow: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

// code element
code {
  color: $text;
  background: $gray-lighten-more;
  display: inline-block;
  padding: rem(2) rem(5);
  border-radius: 3px;
}

// pre element
pre {
  color: $text;
  padding: rem(20);
  border-radius: 3px;
  border: 1px solid $gray-border;
  background: $gray-lighten-more;
}

// ant design font overrides
button,
input,
select,
textarea {
  font-family: inherit;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
  background: #1A1A24 !important;
}