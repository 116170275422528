//.ant-tabs {
//    color:#fff !important;
//}
//.ant-tabs-bar {
//    border-bottom: 0px !important;
//}
//
//.ant-tabs-nav {
//    width:100% !important;
//    border: none !important;
//}
//
//.ant-tabs-tab {
//    background:transparent;
//    font-size:16px;
//    font-family: "EYInterstate-Bold";
//    color:#fff !important;
//    border-radius: 0 !important;
//    width:25%;
//    height:40px;
//}
//
//.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
//    background: transparent !important;
//    border:none !important;
//    border-right:1px #2E2E38 solid !important;
//    height:40px;
//}
//
//.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
//    background: #fff !important;
//    font-size:16px;
//    font-family: "EYInterstate-Bold";
//    color:#2E2E38 !important;
//    border-radius: 0 !important;
//    height:40px;
//}

.ant-tabs {
  .ant-tabs-bar {
    border-bottom: none;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-scroll {
      }
      .ant-tabs-nav {
        display: block;
        > div:not(.ant-tabs-ink-bar) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }
        .ant-tabs-tab {
          width: 100%;
          border-radius: 0 !important;
          text-align: center;
          background: none;
          color: #fff;
          font-family: 'EYInterstate-Bold', sans-serif;
          font-size: 12px;
          letter-spacing: 0;
          display: flex;
          line-height: 18px;
          align-items: center;
          justify-content: center;
          margin: 0;
          border: 1px solid #747480;
          border-right: none;
          text-transform: uppercase;

          &:last-child {
            border-right: 1px solid #747480;
          }

          &.ant-tabs-tab-active {
            background: #fff;
            color: #2e2e38;
          }
        }

        .ant-tabs-ink-bar {
        }
      }
    }
  }
}

.eyButton {
  background-color: #ffe600 !important;
  border: none !important;
  color: #000;
  text-transform: uppercase;
  border-radius: 0 !important;
  height: 32px;
}

.createanewgame01 .auto-flex1-A9yKZG {
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-top: -28px;
  position: relative;
  width: 1024px;
}
.createanewgame01 .auto-flex2-6NTaBf {
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  margin-top: 47px;
  position: relative;
  width: 1024px;
}
.createanewgame01 .overlap-group3-OBCk4b {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 136px;
  position: relative;
  width: 136px;
}
.createanewgame01 .overlap-group4-fNdzON {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 136px;
  margin-left: 18px;
  position: relative;
  width: 136px;
}
.createanewgame01 .auto-flex-p9XfRn {
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 41px;
  justify-content: flex-start;
  margin-right: 11px;
  margin-top: 26px;
  position: relative;
  width: auto;
}
.createanewgame01 .auto-flex3-pvPqPx {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 136px;
  justify-content: flex-start;
  margin-top: 23px;
  position: relative;
  width: auto;
}
.createanewgame01 .overlap-group1-q031xi {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 88px;
  position: relative;
  width: 1024px;
}
.createanewgame01 .overlap-group2-JasynL {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 136px;
  margin-left: 18px;
  position: relative;
  width: 136px;
}
.createanewgame01 .overlap-group-j5kEfD {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 16px;
  margin-top: 5px;
  position: relative;
  width: 27px;
}
.createanewgame01 {
  align-items: center;
  background-color: var(--auto-palette-color-2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  min-height: 768px;
  overflow: hidden;
  position: relative;
  width: 1024px;
}
.createanewgame01 .rectangle-XQxpR0 {
  background-color: var(--auto-palette-color-1);
  height: 40px;
  left: 0px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 221px;
}
.createanewgame01 .footer-small-rn9D4B {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 60px;
  justify-content: flex-start;
  margin-top: 99px;
  position: relative;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .footer-4xUVnB {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 60px;
  justify-content: flex-start;
  position: relative;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .footer-bepJ3V {
  align-items: center;
  background-color: var(--auto-palette-color-1);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  height: 60px;
  justify-content: flex-start;
  min-width: 1024px;
  padding: 21px 30px;
  position: relative;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .a3-features-tVCHds {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-1);
  flex-shrink: 0;
  font-size: 12px;
  height: auto;
  line-height: 18px;
  margin-bottom: 1px;
  margin-left: 9px;
  min-height: 18px;
  position: relative;
  text-align: center;
  transform: rotate(0deg);
  width: 211px;
}
.createanewgame01 .a4-scenario-90yVew {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-1);
  flex-shrink: 0;
  font-size: 12px;
  height: auto;
  line-height: 18px;
  margin-bottom: 1px;
  margin-left: 8px;
  min-height: 18px;
  position: relative;
  text-align: center;
  transform: rotate(0deg);
  width: 200px;
}
.createanewgame01 .button-k69qsx {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 32px;
  margin-left: 308px;
  position: relative;
  transform: rotate(0deg);
  width: 134px;
}
.createanewgame01 .button-oSgxCQ {
  align-items: center;
  background-color: var(--auto-palette-color-5);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  min-width: 134px;
  position: relative;
  top: 0px;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .next-step-xUkFVm {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-2);
  flex-shrink: 0;
  font-size: 12px;
  height: auto;
  line-height: 15px;
  min-height: 16px;
  position: relative;
  text-align: center;
  transform: rotate(0deg);
  width: 136px;
}
.createanewgame01 .line-3-jaoI9e {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 41px;
  margin-left: 10px;
  position: relative;
  transform: rotate(0deg);
  width: 2px;
}
.createanewgame01 .line-3-rmAHMw {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 41px;
  margin-left: 9px;
  position: relative;
  transform: rotate(0deg);
  width: 2px;
}
.createanewgame01 .line-3-fJrizf {
  background-color: rgba(255, 255, 255, 0);
  height: 41px;
  left: 220px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 2px;
}
.createanewgame01 .overlap-group-couKbC {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 41px;
  position: relative;
  width: 222px;
}
.createanewgame01 .bitmap-k55qAN {
  background-color: rgba(255, 255, 255, 0);
  height: 38px;
  left: 7px;
  position: absolute;
  top: 49px;
  transform: rotate(0deg);
  width: 122px;
}
.createanewgame01 .shape-3-xeqe9t {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  height: 38px;
  justify-content: flex-start;
  left: 50px;
  min-width: 38px;
  position: absolute;
  top: 35px;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .shape-o1mUtB {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 37px;
  position: relative;
  transform: rotate(0deg);
  width: 37px;
}
.createanewgame01 .hi-hannah-6PvBbI {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-1);
  font-size: 13px;
  height: auto;
  left: 855px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 49px;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .shape-2-n6sgeg {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  height: 13px;
  justify-content: flex-start;
  left: 830px;
  min-width: 12px;
  position: absolute;
  top: 51px;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .shape-CJ6l1E {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 13px;
  position: relative;
  transform: rotate(0deg);
  width: 11px;
}
.createanewgame01 .copyright-ey-2020-zauL9m {
  align-self: flex-start;
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-4);
  flex-shrink: 0;
  font-size: 13px;
  height: auto;
  line-height: 15px;
  min-height: 15px;
  min-width: 147px;
  position: relative;
  text-align: left;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .privacy-B8gjvf {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-4);
  flex-shrink: 0;
  font-size: 13px;
  height: auto;
  line-height: 15px;
  margin-left: 30px;
  margin-top: 1px;
  min-height: 15px;
  min-width: 72px;
  position: relative;
  text-align: right;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .cellphone-field-copy-MixxwQ {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 48px;
  margin-top: 12px;
  position: relative;
  transform: rotate(0deg);
  width: 442px;
}
.createanewgame01 .rectangle-gWBh2w {
  background-color: var(--auto-palette-color-2);
  height: 48px;
  left: 2px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 439px;
}
.createanewgame01 .line-PlKuK6 {
  background-color: rgba(255, 255, 255, 0);
  height: 1px;
  left: 0px;
  position: absolute;
  top: 47px;
  transform: rotate(0deg);
  width: 442px;
}
.createanewgame01 .take-a-tour-wzkEKm {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-4);
  flex-shrink: 0;
  font-size: 13px;
  height: auto;
  line-height: 15px;
  margin-left: 30px;
  margin-top: 1px;
  min-height: 15px;
  min-width: 102px;
  position: relative;
  text-align: right;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .about-serious-games-IA4yAV {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-4);
  flex-shrink: 0;
  font-size: 13px;
  height: auto;
  line-height: 15px;
  margin-left: 452px;
  margin-top: 1px;
  min-height: 15px;
  min-width: 171px;
  position: relative;
  text-align: right;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .header-small-ZRDt5C {
  align-items: center;
  background-color: var(--auto-palette-color-7);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  height: 60px;
  justify-content: flex-start;
  left: 0px;
  min-width: 1024px;
  padding: 0 35px;
  position: absolute;
  top: 28px;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .group-2-WfIiew {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: auto;
  justify-content: flex-start;
  position: relative;
  transform: rotate(0deg);
  width: 31px;
}
.createanewgame01 .path-LleX0v {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 11px;
  position: relative;
  transform: rotate(0deg);
  width: 31px;
}
.createanewgame01 .rectangle-copy-xR8OjJ {
  background-color: rgba(255, 255, 255, 0);
  border-color: var(--auto-palette-color-3);
  border-style: solid;
  border-width: 1px;
  height: 136px;
  left: 0px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 136px;
}
.createanewgame01 .rectangle-copy-2-0D1RjQ {
  background-color: rgba(255, 255, 255, 0);
  border-color: var(--auto-palette-color-1);
  border-style: solid;
  border-width: 1px;
  height: 136px;
  left: 0px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 136px;
}
.createanewgame01 .shape-xvAVMB {
  background-color: rgba(255, 255, 255, 0);
  height: 16px;
  left: 111px;
  position: absolute;
  top: 8px;
  transform: rotate(0deg);
  width: 16px;
}
.createanewgame01 .rectangle-uxFTlF {
  background-color: rgba(255, 255, 255, 0);
  border-color: var(--auto-palette-color-3);
  border-style: solid;
  border-width: 1px;
  height: 42px;
  left: 59px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 906px;
}
.createanewgame01 .a1-name-company-4CjzzH {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-2);
  font-size: 12px;
  height: auto;
  left: 8px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 11px;
  transform: rotate(0deg);
  width: 211px;
}
.createanewgame01 .a2-audience-roles-mg4R8x {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-1);
  flex-shrink: 0;
  font-size: 12px;
  height: auto;
  line-height: 18px;
  margin-bottom: 1px;
  margin-left: 9px;
  min-height: 18px;
  position: relative;
  text-align: center;
  transform: rotate(0deg);
  width: 211px;
}
.createanewgame01 .create-a-new-game-S8lFRl {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-1);
  flex-shrink: 0;
  font-size: 32px;
  height: auto;
  line-height: 32px;
  margin-left: 2px;
  margin-top: 31px;
  min-height: 34px;
  position: relative;
  text-align: left;
  transform: rotate(0deg);
  width: 906px;
}
.createanewgame01 .shape-7-CyZpIW {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  height: 23px;
  justify-content: flex-start;
  left: 968px;
  position: absolute;
  top: 46px;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .shape-cnc5rx {
  background-color: rgba(255, 255, 255, 0);
  flex-shrink: 0;
  height: 22px;
  position: relative;
  transform: rotate(0deg);
  width: 26px;
}
.createanewgame01 .path-4EH50f {
  background-color: rgba(255, 255, 255, 0);
  height: 16px;
  left: 11px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 16px;
}
.createanewgame01 .path-rPoYJK {
  background-color: rgba(255, 255, 255, 0);
  height: 16px;
  left: 0px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 12px;
}
.createanewgame01 .path-2-EUZr7T {
  background-color: rgba(255, 255, 255, 0);
  height: 10px;
  left: 114px;
  position: absolute;
  top: 9px;
  transform: rotate(0deg);
  width: 13px;
}
.createanewgame01 .group-3-xjyM1B {
  background-color: rgba(255, 255, 255, 0);
  height: 95px;
  left: 13px;
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
  width: 111px;
}
.createanewgame01 .the-cyber-war-game-ZirXHL {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-1);
  font-size: 16px;
  height: auto;
  left: 2px;
  line-height: 24px;
  position: absolute;
  text-align: left;
  top: 11px;
  transform: rotate(0deg);
  width: 441px;
}
.createanewgame01 .rectangle-53xfC1 {
  background-color: var(--auto-palette-color-1);
  border-color: var(--auto-palette-color-1);
  border-style: solid;
  border-width: 1px;
  height: 136px;
  left: 0px;
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
  width: 136px;
}
.createanewgame01 .give-your-game-a-nam-oqWxlY {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-3);
  flex-shrink: 0;
  font-size: 13px;
  height: auto;
  line-height: 18px;
  margin-right: 269px;
  margin-top: 47px;
  min-height: 18px;
  min-width: 183px;
  position: relative;
  text-align: left;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .choose-a-company-to-fyUfQx {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-3);
  flex-shrink: 0;
  font-size: 13px;
  height: auto;
  line-height: 18px;
  margin-right: 203px;
  margin-top: 36px;
  min-height: 18px;
  min-width: 249px;
  position: relative;
  text-align: left;
  transform: rotate(0deg);
  width: auto;
}
.createanewgame01 .new-company-Rdk3AA {
  background-color: rgba(255, 255, 255, 0);
  color: var(--auto-palette-color-3);
  font-size: 13px;
  height: auto;
  left: 1px;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 87px;
  transform: rotate(0deg);
  width: 134px;
}
/* .anima-animate-appear prefix for appear animations */
.anima-animate-appear {
  opacity: 0;
  display: block;
  animation: anima-reveal 0.3s ease-in-out 1 normal forwards;
}
.anima-animate-disappear {
  opacity: 1;
  display: block;
  animation: anima-reveal 0.3s ease-in-out 1 reverse forwards;
}
.anima-animate-nodelay {
  animation-delay: 0s;
}
@keyframes anima-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
* {
  box-sizing: border-box;
}
.anima-component-wrapper,
.anima-component-wrapper * {
  pointer-events: none;
}
.anima-component-wrapper a *,
.anima-component-wrapper a,
.anima-component-wrapper input,
.anima-component-wrapper video,
.anima-component-wrapper iframe,
.anima-listeners-active,
.anima-listeners-active * {
  pointer-events: auto;
}
.anima-hidden,
.anima-hidden * {
  visibility: hidden;
  pointer-events: none;
}
.anima-smart-layers-pointers,
.anima-smart-layers-pointers * {
  pointer-events: auto;
  visibility: visible;
}
.anima-component-wrapper.anima-not-ready,
.anima-component-wrapper.anima-not-ready * {
  visibility: hidden !important;
}
.anima-listeners-active-click,
.anima-listeners-active-click * {
  cursor: pointer;
}
.anima-component-wrapper a,
.anima-screen a {
  text-decoration: none;
}
.anima-flexbox-container a {
  line-height: 0;
}
.anima-full-width-a {
  width: 100%;
}
.anima-full-height-a {
  height: 100%;
}
.anima-screen textarea:focus,
.anima-screen input:focus {
  outline: none;
}
.anima-screen div {
  -webkit-text-size-adjust: none;
}
.anima-container-center-vertical,
.anima-container-center-horizontal {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}
.anima-container-center-vertical {
  align-items: center;
  height: 100%;
}
.anima-container-center-horizontal {
  justify-content: center;
  width: 100%;
}
.anima-container-center-vertical > *,
.anima-container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}
.anima-component-wrapper,
.anima-screen {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
}
