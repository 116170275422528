// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select {
  font-family: 'EYInterstate-Bold', sans-serif;
  width: 100%;

  &.ant-select-focused {
  }

  .ant-select-selection {
    border: none;
    box-shadow: none !important;
    background: transparent !important;
    color: #fff;
    border-bottom: 1px solid white;
    font-size: 20px;
    padding: 5px 0px;
    height: auto;
    border-radius: 0 !important;
    font-family: 'EYInterstate-Light', sans-serif;
    outline: 0;
  }

  .ant-select-arrow {
    color: #fff;
    font-size: 20px;
    margin-top: -10px;
  }
}

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
  border-radius: 0 !important;

  .ant-select-dropdown-menu-item {
    &:hover,
    &.ant-select-dropdown-menu-item-selected {
      background-color: #ffe600;
    }

    img {
      width: 30px;
      margin-right: 5px;
    }
  }
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}
