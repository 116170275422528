// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-lighten-more-more;
}

.ant-input {
  background: transparent !important;
  color: #fff;
  border: none;
  border-bottom: 1px solid white;
  font-size: 20px;
  padding: 5px 0px;
  height: auto;
  border-radius: 0 !important;
  font-family: 'EYInterstate-Light', sans-serif;

  &:focus {
    border-color: #ffe600;
  }
  &:hover {
    border-color: #ffe600;
  }

  &.block {
    display: block;
  }
}

.ant-form-item-label {
  line-height: 18px;
  font-size: 13px;
  label {
    font-family: 'EYInterstate-Bold', sans-serif;
    font-size: 13px;
    color: #747480;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: uppercase;
  }
}
