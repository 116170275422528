.ant-btn {
  background: #ffe600;
  font-family: 'EYInterstate-Bold', sans-serif;
  font-size: 14px;
  color: #2e2e38;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 40px !important;
  height: auto;
  border-radius: 0;
  border: none;
  outline: 0;

  &:hover {
    background: #666;
    color: #ffe600;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.gray {
    background: #747480;
  }

  &.naked {
    background: transparent;
    color: #fff;
    padding-left: 0 !important;
    padding-right: 0 !important;

    img {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }
  }
}

a.ant-btn {
  background: #ffe600;
  font-family: 'EYInterstate-Bold', sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2e2e38;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 40px !important;
  height: auto;
  border-radius: 0;
  border: none;
  outline: 0;

  &:hover {
    background: #666;
    color: #ffe600;
  }

  &.block {
    display: block;
    width: 100%;
  }
}
